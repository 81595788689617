
import BaseModal from "plugins/modal/index.js";                          // /**
import { modal_close_button } from "plugins/modal/templates/buttons.js";//  * @copyright The Converse.js contributors
import log from '@converse/headless/log';                               //  * @license Mozilla Public License (MPLv2)
import { CustomElement } from 'shared/components/element.js';           //  */
import { html } from "lit";
const { Strophe, $iq } = converse.env;

import _converse from "@converse/headless/shared/_converse.js"
import api, { converse } from "@converse/headless/shared/api/index.js";



converse.plugins.add('converse-katex', {
    enabled (_converse) {
        return (
            !_converse.api.settings.get('blacklisted_plugins').includes('converse-katex')
        );
    },

    //dependencies: ['converse-chatview', 'converse-pubsub'],
    dependencies: [],


    initialize () {
        /******************** Event Handlers ********************/

	_converse.api.listen.on('afterMessageBodyTransformed', function(text) {
		renderLatex(text);
	});
    }
});


function renderLatex(text) {

	const findEndOfMath = function(delimiter, text, startIndex) {
	    // Adapted from
	    // https://github.com/Khan/perseus/blob/master/src/perseus-markdown.jsx
	    let index = startIndex;
	    let braceLevel = 0;

	    const delimLength = delimiter.length;

	    while (index < text.length) {
		const character = text[index];

		if (braceLevel <= 0 &&
		    text.slice(index, index + delimLength) === delimiter) {
		    return index;
		} else if (character === "\\") {
		    index++;
		} else if (character === "{") {
		    braceLevel++;
		} else if (character === "}") {
		    braceLevel--;
		}

		index++;
	    }

	    return -1;
	};

	const escapeRegex = function(string) {
	    return string.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
	};

	const amsRegex = /^\\begin{/;

	const splitAtDelimiters = function(text, delimiters) {
	    let index;
	    const data = [];

	    const regexLeft = new RegExp(
		"(" + delimiters.map((x) => escapeRegex(x.left)).join("|") + ")"
	    );

	    while (true) {
		index = text.search(regexLeft);
		if (index === -1) {
		    break;
		}
		if (index > 0) {
		    data.push({
			type: "text",
			data: text.slice(0, index),
		    });
		    text = text.slice(index); // now text starts with delimiter
		}
		// ... so this always succeeds:
		const i = delimiters.findIndex((delim) => text.startsWith(delim.left));
		index = findEndOfMath(delimiters[i].right, text, delimiters[i].left.length);
		if (index === -1) {
		    break;
		}
		const rawData = text.slice(0, index + delimiters[i].right.length);
		const math = amsRegex.test(rawData)
		    ? rawData
		    : text.slice(delimiters[i].left.length, index);
		data.push({
		    type: "math",
		    data: math,
		    rawData,
		    display: delimiters[i].display,
		});
		text = text.slice(index + delimiters[i].right.length);
	    }

	    if (text !== "") {
		data.push({
		    type: "text",
		    data: text,
		});
	    }

	    return data;
	};


	const standardDelims = [
		  {left: "$$", right: "$$", display: true},
		  {left: "\\(", right: "\\)", display: false},
		  {left: "\\begin{equation}", right: "\\end{equation}", display: true},
		  {left: "\\begin{align}", right: "\\end{align}", display: true},
		  {left: "\\begin{alignat}", right: "\\end{alignat}", display: true},
		  {left: "\\begin{gather}", right: "\\end{gather}", display: true},
		  {left: "\\begin{CD}", right: "\\end{CD}", display: true},
		  {left: "\\[", right: "\\]", display: true}
		];
	var splitText = []
	try { splitText = splitAtDelimiters(text, standardDelims); } catch(err) {
		// console.log(err);
		splitText = [];
	}
	if (splitText.length <= 0) {
		return;
	}


	try {
	let startIdx = 0;

	splitText.forEach((x) => {
		if (x.type === 'math') {
			var newElem = document.createElement("span");
			try { katex.render(x.data, newElem, {
				displayMode: x.display,
				throwOnError: false,
				maxExpand: 100,
				maxSize: '500em',
				trust: false
			}); } catch(err) {
				console.log(err);
				return;
			}
			const newElemChild = newElem.firstChild;
			text.addTemplateResult(startIdx, startIdx + x.rawData.length, html`${newElemChild}`);
			startIdx = startIdx + x.rawData.length;
		} else {
			startIdx = startIdx + x.data.length;
		}
	});
	
	} catch(err) {
		console.log(err);
	}

}
 
